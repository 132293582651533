import { USER_ROLES, USER_ROLE_TYPES } from '../common/constants';
import { homeGuard } from '../guards/homeGuard';
import { validateInviteToken } from '../guards/inviteTokenValidationGuard';
import { premiumPayment } from '../guards/premiumPaymentGuard';
import { dashboardGuard } from '../guards/dashboardGuard';

import { basicPayment } from '../guards/basicPaymentGuard';
import { inAppPaymentGuard } from '../guards/inAppPaymentGuard';
import { isStoryExistsGaurd } from '../guards/isStoryExistsGaurd';
import { validateResetPasswordToken } from '../guards/resetPasswordTokenValidationGuard';

const Layout = () => import(/* webpackChunkName: "layout" */ '../views/Layout.vue');
// const Home = () => import(/* webpackChunkName: "home" */ '../pages/_layout/index.vue');

export const routes = [
  // {
  //   path: '/',
  //   name: '/',
  //   component: Home,
  //   meta: { auth: true }
  // },

  // Default Routes
  {
    path: '/',
    // id: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        // component: Home,
        component: Layout,
        meta: { auth: true },
        beforeEnter: (to, from, next) => {
          homeGuard(to, from, next);
        },
      },

      // Auth Routes
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SignIn.vue'),
      },
      {
        path: 'logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/_layout/logout.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/ForgotPass.vue'),
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/ResetPassword.vue'),
        beforeEnter: validateResetPasswordToken,
      },
      {
        path: 'accept-invite',
        name: 'accept-invite',
        component: () => import(/* webpackChunkName: "std" */ '../views/Auth/AcceptInvite.vue'),
        beforeEnter: validateInviteToken,
      },
      {
        path: 'student-signup',
        name: 'student-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/StudentSignUp.vue'),
        meta: { auth: false, onlyUnauth: true },
      },
      {
        path: 'employer-signup',
        name: 'employer-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/EmployerSignUp.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'verify-employer-email/:verificationId',
        name: 'verify-employer-email',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/VerifyEmployerEmail.vue'),
        meta: { auth: false },
      },
      {
        path: 'school-signup',
        // name: 'school-signup',
        redirect: 'school-interest',
        // component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SchoolSignUp.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'school-interest',
        name: 'school-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SchoolSignUp.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'school-interest/:submission',
        name: 'verify-school-signup',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SchoolSignupSuccessComponent.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },

      {
        path: 'sponsorship-spotlight',
        name: 'sponsorship-spotlight',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SponsorshipSpotlight.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: false },
      },

      {
        path: 'manage-preference',
        name: 'manage-preference',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SubscriptionComponent.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: false },
      },
      {
        path: 'sponsorship-spotlight/:submission',
        name: 'verify-sponsorship-spotlight',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SpotlightSuccessComponent.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: false },
      },
      {
        path: 'advisor-onboarding/confirmation/:submission',
        name: 'advisor-onboarding-confirm',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Advisors/AdvisorSignupConfirm.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'advisor-onboarding/SdwT4rYq',
        name: 'uni-advisor-onboarding',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Advisors/AdvisorSignup.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },
      {
        path: 'advisor-onboarding/:inviteId',
        name: 'org-advisor-onboarding',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Advisors/AdvisorSignup.vue'),
        meta: { auth: false, onlyUnauth: true, onlyMainDomain: true },
      },

      //Assessment Managment
      {
        path: 'assessments',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-assessments',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/StudentAssessments/AssessList.vue'),
          },

          {
            path: ':id',
            name: 'assessment-detail',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/StudentAssessments/AssessDetails.vue'),
          },
        ],
      },
      //User Resume
      {
        path: 'user-resumes',
        name: 'user-resumes-list',
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/ResumeBuilder/ResumeBuilderList.vue'),
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN],
        },
      },
      //Website Config Routes
      {
        path: '/website-configuration',
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: Layout,
        children: [
          {
            path: 'basic',
            name: 'basic-config',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/WebsiteConfig/BasicPageConfig.vue'),
          },
          {
            path: 'home',
            name: 'homepage-config',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/WebsiteConfig/HomePageConfig.vue'),
          },
          {
            path: 'pages-config',
            name: 'pages-config',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/WebsiteConfig/PagesConfig.vue'),
          },
        ],
      },

      //SignUp Requests
      {
        path: 'sign-up-requests',
        name: 'manage-student-signup-list',
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.SCHOOL_OWNER] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/StudentSignup/StudentSignupList.vue'),
      },
      {
        path: 'sign-up-requests/:id',
        name: 'show-student-sign-up-request',
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.SCHOOL_OWNER] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/StudentSignup/SignUpRequestShowPage.vue'),
      },

      {
        path: 'application-form',
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
        component: Layout,
        children: [
          {
            path: 'add/:id',
            name: 'manage-in-app-form',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Applications/PostApp.vue'),
          },
          {
            path: ':sponsorship/add-section',
            name: 'add-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/OrganizationPortal/ApplicationFormSettings/AddSectionPage.vue'
              ),
          },
          {
            path: ':sponsorship/edit-section/:id',
            name: 'edit-section',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/OrganizationPortal/ApplicationFormSettings/AddSectionPage.vue'
              ),
          },
          {
            path: 'configure-student-info',
            name: 'configure-reg-static-fields',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/OrganizationPortal/ApplicationFormSettings/StaticFieldsPage.vue'
              ),
          },
          {
            path: ':id',
            name: 'manage-application-form',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/OrganizationPortal/ApplicationFormSettings/ManageApplicationFrom.vue'
              ),
          },
        ],
      },

      // Account/Profile Routes
      {
        path: 'my-profile',
        name: 'my-profile',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ManageProfile.vue'),
        meta: { auth: true },
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ChangePass.vue'),
        meta: { auth: true },
      },
      //Partnership Route
      {
        path: 'manage-partnerships',
        name: 'manage-partnerships',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/PartnershipList.vue'),
        meta: { auth: true },
      },
      {
        path: 'manage-partnered-universities',

        name: 'manage-partnered-universities',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/UniversitiesList.vue'),
        meta: { auth: true },
      },
      {
        path: 'manage-high-school-districts',

        name: 'manage-high-school-districts',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/HighSchoolList.vue'),
        meta: { auth: true },
      },
      {
        path: 'manage-school-organizations',

        name: 'manage-school-organizations',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/OrgsList.vue'),
        meta: { auth: true },
      },
      {
        path: 'partnership-profile',
        name: 'partnership-profile',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/EditSchoolProfile.vue'),
        meta: { auth: true },
      },
      {
        path: 'spotlights',

        component: Layout,
        children: [
          {
            path: '',
            name: 'spotlight-list',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
                USER_ROLES.SCHOOL_ADMIN,
                USER_ROLES.SCHOOL_OWNER,
                USER_ROLES.SUPER_ADMIN,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/SponsorInterest/StdList.vue'),
          },
          {
            path: ':id',
            name: 'get-spotlight',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
                USER_ROLES.SCHOOL_ADMIN,
                USER_ROLES.SCHOOL_OWNER,
                USER_ROLES.SUPER_ADMIN,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/EmployerPortal/SponsorInterest/StdDetailPage.vue'),
          },
        ],
      },
      // {
      //   path: 'request-partnership',
      //   name: 'request-partnership',
      //   component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/RequestPartnershipPage.vue'),
      //   meta: { auth: true }
      // },

      // Jobs Management
      {
        path: 'manage-jobs',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [
            USER_ROLES.SUPER_ADMIN,
            USER_ROLES.EMPLOYER_ADMIN,
            USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
            USER_ROLES.ORGANIZATION,
          ],
        },
        children: [
          {
            path: '',
            name: 'jobs-management-list',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Jobs/JobsList.vue'),
          },
          {
            path: 'post',
            name: 'post-job',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Jobs/PostJob/PostJob.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-job',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Jobs/PostJob/PostJob.vue'),
          },
          {
            path: 'public-view/:id',
            name: 'manage-job-public-view',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Jobs/JobShowPage.vue'),
          },
        ],
      },
      {
        path: 'simulations',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST],
        },
        children: [
          {
            path: 'listings',
            name: 'simulations-management-list',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Simulations/SimulationsList.vue'),
          },
          {
            path: 'add',
            name: 'post-simulation',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "gen" */ '../views/Simulations/PostSimulation/PostSimulation.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-simulation',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "gen" */ '../views/Simulations/PostSimulation/PostSimulation.vue'),
          },
          {
            path: 'submissions',
            name: 'simulations-submission-list',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Simulations/SimulationSubmissionList.vue'),
          },
          {
            path: 'submissions/:id',
            name: 'simulations-submission-profile',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN],
            },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/Simulations/SimulationSubmissionProfile.vue'),
          },
        ],
      },
      {
        path: 'call-logs',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
        },
        children: [
          {
            path: '',
            name: 'calls-logs',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/CallLogs/CallLogs.vue'),
          },
          {
            path: 'call-logs/:id',
            name: 'call-profile',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER],
            },
            component: () => import(/* webpackChunkName: "adm" */ '../views/CallLogs/CallLogProfile.vue'),
          },
        ],
      },
      {
        path: 'sponsorships',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST],
        },
        children: [
          {
            path: '',
            name: 'scholarships-management-list',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/Scholarships/ManageScholarshipsList.vue'),
          },
          {
            path: 'add',
            name: 'post-scholarship',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "gen" */ '../views/Scholarships/PostScholarship/PostScholarship.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-scholarship',
            meta: {
              auth: true,
              rolesRequired: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.EMPLOYER_ADMIN,
                USER_ROLES.EMPLOYER_CAREER_SPECIALIST,
                USER_ROLES.ORGANIZATION,
              ],
            },
            component: () =>
              import(/* webpackChunkName: "gen" */ '../views/Scholarships/PostScholarship/PostScholarship.vue'),
          },
          {
            path: 'submissions',
            name: 'std-apps-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Applications/AppList.vue'),
          },
          {
            path: 'submissions/:id',
            name: 'app-profile',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLES.EMPLOYER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Applications/AppProfile.vue'),
          },
        ],
      },
      {
        path: 'external-sponsorships',
        component: Layout,
        meta: {
          auth: true,
          rolesRequired: [USER_ROLES.SUPER_ADMIN],
        },
        children: [
          {
            path: '',
            name: 'external-scholarships-list',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN],
            },
            component: () =>
              import(/* webpackChunkName: "gen" */ '../views/ExternalScholarships/ManageScholarshipsList.vue'),
          },
          {
            path: 'add',
            name: 'post-external-scholarship',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/ExternalScholarships/PostScholarship.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-external-scholarship',
            meta: {
              auth: true,
              rolesRequired: [USER_ROLES.SUPER_ADMIN],
            },
            component: () => import(/* webpackChunkName: "gen" */ '../views/ExternalScholarships/PostScholarship.vue'),
          },
        ],
      },

      //Public Sponsorship
      {
        path: 'sponsorships/:id',
        name: 'public-scholarship',

        component: () => import(/* webpackChunkName: "std" */ '../views/PublicSholarship/PublicSchDetailPage.vue'),
      },
      //GPTs Management
      {
        path: 'gpts',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-gpts',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Gpts/ListGpts.vue'),
          },

          {
            path: 'add',
            name: 'add-gpt',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Gpts/AddGpt.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-gpt',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Gpts/AddGpt.vue'),
          },
        ],
      },
      //Users Management
      {
        path: 'users',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-users',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Users/ListUsers.vue'),
          },

          {
            path: 'add',
            name: 'add-user',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Users/PostUser.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-user',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Users/PostUser.vue'),
          },
        ],
      },

      // Payments & Billing
      {
        path: 'billing',
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
        component: Layout,
        name: 'manage-billing',
        redirect: 'billing/payment-method',
        children: [
          // Manage Payment Method
          {
            path: 'payment-method',
            name: 'manage-payment-method',
            component: () => import(/* webpackChunkName: "account" */ '../views/Payment/PaymentDetailPage.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
            beforeEnter: inAppPaymentGuard,
          },
          {
            path: 'payment-history',
            name: 'manage-payment-history',
            component: () => import(/* webpackChunkName: "account" */ '../views/Payment/PaymentHistory.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
          },
          {
            path: 'credits-history',
            name: 'manage-credits-history',
            component: () => import(/* webpackChunkName: "account" */ '../views/Payment/CreditsHistory.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.ORGANIZATION] },
          },
          {
            path: 'purchase-tokens',
            name: 'manage-purchase-tokens',
            component: () => import(/* webpackChunkName: "account" */ '../views/Payment/PurchaseTokens.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.ORGANIZATION] },
          },
        ],
      },

      //Skills
      {
        path: 'skills',
        component: Layout,
        beforeEnter: premiumPayment,
        children: [
          {
            path: '',
            name: 'student-skill',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Skills/Skills.vue'),
          },
          {
            path: 'practice',
            name: 'student-practice-skill',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Skills/PracticeSkill.vue'),
          },
          {
            path: ':id',
            name: 'student-skill-details',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Skills/SkillDetailPage.vue'),
          },
        ],
      },
      //Skills
      {
        path: 'skills-configuration',
        component: Layout,
        beforeEnter: premiumPayment,
        children: [
          {
            path: '',
            name: 'manage-skill-config',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/StudentPortal/Skills/ManageSkillConfig.vue'),
          },
          {
            path: 'new',
            name: 'new-skill-config',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/StudentPortal/Skills/ConfigureSkill.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-skill-config',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "sch" */ '../views/StudentPortal/Skills/ConfigureSkill.vue'),
          },
        ],
      },
      {
        path: 'ask-question',
        name: 'add-ques',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Questions/AddQuestion.vue'),
      },
      {
        path: 'questions',
        component: Layout,
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        beforeEnter: premiumPayment,
        children: [
          {
            path: '',
            name: 'student-questions',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Questions/QuestionList.vue'),
          },
          {
            path: ':id',
            name: 'student-question-details',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () =>
              import(/* webpackChunkName: "std" */ '../views/StudentPortal/Questions/QuesDetailPage.vue'),
          },
        ],
      },
      {
        path: 'my-questions',

        component: Layout,
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        beforeEnter: premiumPayment,
        children: [
          {
            path: '',
            name: 'my-questions',
            component: () =>
              import(/* webpackChunkName: "std" */ '../views/StudentPortal/Questions/ManageQuesPage.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
          },

          {
            path: ':id',
            name: 'edit-ques',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Questions/AddQuestion.vue'),
          },
        ],
      },
      {
        path: 'my-certifications',
        component: Layout,
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        // beforeEnter: premiumPayment,
        children: [
          {
            path: '',
            name: 'my-certifications',
            component: () =>
              import(/* webpackChunkName: "std" */ '../views/StudentPortal/Certificates/CertificatesList.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
          },
          {
            path: 'add-certificate',
            name: 'add-certificate',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () =>
              import(/* webpackChunkName: "std" */ '../views/StudentPortal/Certificates/AddCertificate.vue'),
          },
          {
            path: ':id',
            name: 'edit-certificate',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () =>
              import(/* webpackChunkName: "std" */ '../views/StudentPortal/Certificates/AddCertificate.vue'),
          },
        ],
      },
      {
        path: 'my-offers',
        component: Layout,
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        beforeEnter: dashboardGuard,
        children: [
          {
            path: '',
            name: 'my-offers',
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Offers/OffersList.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
          },
          {
            path: 'add-offer',
            name: 'add-offer',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Offers/AddOffer.vue'),
          },
          {
            path: ':id',
            name: 'edit-offer',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Offers/AddOffer.vue'),
          },
        ],
      },
      {
        path: 'my-courses',
        component: Layout,
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        // beforeEnter: dashboardGuard,
        children: [
          {
            path: '',
            name: 'my-courses',
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Courses/CoursesList.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
          },
          {
            path: 'add-course',
            name: 'add-course',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Courses/AddCourse.vue'),
          },
          {
            path: ':id',
            name: 'edit-course',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Courses/AddCourse.vue'),
          },
        ],
      },

      // Employer pages redirection.
      {
        path: 'redirect-employer',
        component: Layout,
        children: [
          {
            path: '',
            name: 'redirect-employer',
            component: () => import(/* webpackChunkName: "std" */ '../views/Employers/RedirectEmployers.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
          },
          {
            path: ':id',
            name: 'redirect-employer-profile',
            component: () => import(/* webpackChunkName: "std" */ '../views/Employers/RedirectEmployers.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
          },
        ],
      },
    ],
  },

  // Super Admin Management Routes
  {
    path: '/admin',
    meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
    component: Layout,
    children: [
      // Dashboard/Home
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Dashboard/Dashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
      },
      {
        path: 'manage-all-partnerships',
        name: 'manage-all-partnerships',
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Partnerships/PartnershipsList.vue'),
      },
      {
        path: 'feature-updates',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-features',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/FeatureUpdates/ManageFeatureUpdates.vue'),
          },
          {
            path: 'add',
            name: 'add-features',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/FeatureUpdates/AddFeatureUpdate.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-features',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/FeatureUpdates/AddFeatureUpdate.vue'),
          },
        ],
      },
      {
        path: 'interested-schools',
        component: Layout,
        children: [
          {
            path: 'approve-interested-school',
            name: 'add-interested-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/PostSchool.vue'),
          },
          {
            path: '',
            name: 'manage-interested-schools',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/ListInterestedSchools.vue'),
          },
          {
            path: ':id',
            name: 'interested-school-profile',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/InterestedSchoolProfile.vue'),
          },
        ],
      },
      // Schools Management
      {
        path: 'schools',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-schools',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/ListSchools.vue'),
          },
          {
            path: 'add',
            name: 'add-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/PostSchool.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-school',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Schools/PostSchool.vue'),
          },
        ],
      },

      // Schools Management
      {
        path: 'partners',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-partners',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Partners/ListPartners.vue'),
          },
          {
            path: 'add',
            name: 'add-partner',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Partners/PostPartner.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-partner',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Partners/PostPartner.vue'),
          },
        ],
      },
      // University Management
      {
        path: 'sponsored-training/universities',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-unis',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Universities/ListUnis.vue'),
          },
          {
            path: 'add',
            name: 'add-uni',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Universities/PostUniversity.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-uni',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Universities/PostUniversity.vue'),
          },
        ],
      },

      // Organization Management
      {
        path: 'organizations',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-organizations',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Organizations/ListOrganizations.vue'),
          },
          {
            path: 'add',
            name: 'add-organization',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Organizations/PostOrganization.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-organization',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Organizations/PostOrganization.vue'),
          },
        ],
      },

      // Prize Management
      {
        path: 'prize',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-prize',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Prize/ListPrize.vue'),
          },
          {
            path: 'manage-redeem-prizes',
            name: 'manage-redeem-prizes',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Prize/ListRedeemedPrize.vue'),
          },
          {
            path: 'add',
            name: 'add-prize',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Prize/PostPrize.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-prize',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Prize/PostPrize.vue'),
          },
        ],
      },

      // Students Management
      {
        path: 'students',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-students',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Students/ListStudents.vue'),
          },
          {
            path: 'add',
            name: 'add-student',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Students/PostStudent.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-student',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Students/PostStudent.vue'),
          },
        ],
      },

      // Employers Management
      {
        path: 'employers',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-employers',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Employers/ListEmployers.vue'),
          },
          {
            path: 'add',
            name: 'add-employer',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Employers/PostEmployer.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-employer',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Employers/PostEmployer.vue'),
          },
        ],
      },

      // Career Preferences Management
      {
        path: 'career-pathways',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-career-pathways',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/SuperAdminPortal/CareerPreferences/ListCareerPathways.vue'
              ),
          },
          {
            path: 'add',
            name: 'sa-add-career-pathway',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/SuperAdminPortal/CareerPreferences/PostCareerPreference.vue'
              ),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-career-pathway',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(
                /* webpackChunkName: "adm" */ '../views/SuperAdminPortal/CareerPreferences/PostCareerPreference.vue'
              ),
          },
        ],
      },
      //Programs Management
      {
        path: 'programs',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-programs',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Programs/ProgramList.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Programs/PostProgram.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-program',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Programs/PostProgram.vue'),
          },
        ],
      },

      //Blog Management
      {
        path: 'blog',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-blogs',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Blogs/ListBlog.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-blog',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Blogs/AddBlog.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-blog',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Blogs/AddBlog.vue'),
          },
        ],
      },
      //Resources Management
      {
        path: 'manage-resources',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-resources',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/ListResources.vue'),
          },
          {
            path: 'add',
            name: 'add-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/AddResource.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/AddResource.vue'),
          },
        ],
      },
      // Events Management
      {
        path: 'events',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-events',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Events/ListEvents.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-event',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Events/PostEvent.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-event',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Events/PostEvent.vue'),
          },
        ],
      },
      //Connnections
      {
        path: 'sponsored-training/connect-requests',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-connections',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Connections/ListConnections.vue'),
          },
        ],
      },
      //Best School States
      {
        path: 'sponsored-training/best-school-states',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-states',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/ListStates.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-state',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddState.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-state',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddState.vue'),
          },
        ],
      },
      //Best School Cities
      {
        path: 'sponsored-training/high-school-districts',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-cities',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/ListCity.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-city',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddCity.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-city',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/BestSchoolLocation/AddCity.vue'),
          },
        ],
      },

      {
        path: 'system-config',
        name: 'system-config',
        meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/SystemConfig/SystemConfig.vue'),
      },

      // Advisors
      {
        path: 'advisors',
        component: Layout,
        children: [
          {
            path: '',
            name: 'sa-manage-advisors',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Advisors/ManageAdvisors.vue'),
          },
          {
            path: 'add',
            name: 'sa-add-advisor',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Advisors/PostAdvisor.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sa-edit-advisor',
            meta: { auth: true, rolesRequired: [USER_ROLES.SUPER_ADMIN] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/Advisors/PostAdvisor.vue'),
          },
        ],
      },
    ],
  },
  //Organization Portal
  {
    path: '/organization',
    meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION] },
    component: Layout,
    name: 'organization-home',

    redirect: 'organization/dashboard',

    children: [
      //School Profile
      {
        path: 'org-profile',
        name: 'manage-org-profile',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/Profile/ManageOrgProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'dashboard',
        name: 'org-admin-home',
        component: () => import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/Dashboard/Dashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'setup-guide',
        name: 'org-setup-guide',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/SetupGuide/OrgSetupGuide.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      // Schools
      {
        path: 'schools',
        name: 'org-schools-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/Schools.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'schools/:id',
        name: 'org-school-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/SchoolProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'candidates',
        name: 'org-students-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/Students.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'unlock-candidates',
        name: 'org-unlock-students-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/Students.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'unlock-candidates/:id',
        name: 'org-unlocked-candidate-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },

      {
        path: 'associated-candidates',
        name: 'associated-students-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/Students.vue'),
        meta: {
          auth: true,
          rolesRequired: [
            USER_ROLES.ORGANIZATION,
            USER_ROLE_TYPES.ORGANIZATION,
            USER_ROLES.EMPLOYER_ADMIN,
            USER_ROLE_TYPES.EMPLOYER,
          ],
        },
      },
      {
        path: 'associated-candidates/:id',
        name: 'associated-candidate-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/StudentProfile.vue'),
        meta: {
          auth: true,
          rolesRequired: [
            USER_ROLES.ORGANIZATION,
            USER_ROLE_TYPES.ORGANIZATION,
            USER_ROLES.EMPLOYER_ADMIN,
            USER_ROLE_TYPES.EMPLOYER,
          ],
        },
      },

      {
        path: 'candidates/:id',
        name: 'org-student-details',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
      },
      {
        path: 'events',
        component: Layout,
        redirect: 'events/',

        children: [
          {
            path: '',
            name: 'manage-org-events',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/Events/EventsList.vue'),
          },
          {
            path: 'add',
            name: 'add-org-event',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Events/PostEvent.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-org-event',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Events/PostEvent.vue'),
          },
        ],
      },
      {
        path: 'resources',
        component: Layout,
        redirect: 'resources/',

        children: [
          {
            path: '',
            name: 'manage-org-resources',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/Resource/ListResource.vue'),
          },
          {
            path: 'add',
            name: 'add-org-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/Resource/AddResource.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-org-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/OrganizationPortal/Resource/AddResource.vue'),
          },
        ],
      },

      {
        path: 'employers',
        component: Layout,
        redirect: 'employers/',

        children: [
          {
            path: '',
            name: 'manage-org-employers',
            component: () =>
              import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/Employers/EmployersList.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
          },
          {
            path: 'edit/:id',
            name: 'edit-org-employer',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Employers/PostEmployer.vue'),
          },
          {
            path: 'public-profile/:id',
            name: 'org-emp-public-profile',
            component: () => import(/* webpackChunkName: "emp" */ '../views/Employers/EmployerProfile.vue'),
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
          },
        ],
      },

      {
        path: 'custom-questions',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-questions',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/Questions/QuestionsList.vue'),
          },
          {
            path: 'add',
            name: 'add-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/OrganizationPortal/Questions/PostQuestion.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/OrganizationPortal/Questions/PostQuestion.vue'),
          },
        ],
      },

      {
        path: 'inquiries',
        component: Layout,
        children: [
          {
            path: '',
            name: 'org-inquiries-list',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "org-inq" */ '../views/OrganizationPortal/Inquiries/InquiriesList.vue'),
          },
          {
            path: ':id',
            name: 'org-inquiry-details',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION, USER_ROLE_TYPES.ORGANIZATION] },
            component: () =>
              import(/* webpackChunkName: "org-inq" */ '../views/OrganizationPortal/Inquiries/InquiryDetails.vue'),
          },
        ],
      },

      {
        path: 'advisors',
        component: Layout,
        children: [
          {
            path: '',
            name: 'manage-org-advisors',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "org-inq" */ '../views/Advisors/ManageAdvisors.vue'),
          },
          {
            path: 'add',
            name: 'add-org-advisor',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "org-inq" */ '../views/Advisors/PostAdvisor.vue'),
          },
          {
            path: 'edit/:id',
            name: 'edit-org-advisor',
            meta: { auth: true, rolesRequired: [USER_ROLES.ORGANIZATION] },
            component: () => import(/* webpackChunkName: "org-inq" */ '../views/Advisors/PostAdvisor.vue'),
          },
        ],
      },
    ],
  },

  // School Portal Routes
  {
    path: '/school',
    meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
    component: Layout,
    name: 'school-home',
    redirect: 'school/dashboard',
    children: [
      //School Profile
      {
        path: 'dashboard',
        name: 'dashboard-school',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/dashboard/SchoolDashboard.vue'),
      },
      {
        path: 'integrations',
        name: 'school-integrations',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/SchoolPortal/Integrations/Integrations.vue'),
      },
      {
        path: 'school-profile',
        name: 'manage-school-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Profile/ManageSchoolProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'student-dashboard',
        name: 'manage-student-dashboard',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/SchoolPortal/Profile/ManageStudentDashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'school-profile/public-view',
        name: 'school-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/SchoolProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      // Students
      {
        path: 'students',
        name: 'schl-students-list',
        component: () => import(/* webpackChunkName: "sch" */ '../views/Students/Students.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'configure-student-docs',
        name: 'configure-student-docs',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Settings/ConfigurePiisPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'secure-docs',
        name: 'schl-std-secure-docs',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/SecureDocs/StdSecureDocsList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'generate-report',
        name: 'generate-report',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/SecureDocs/ReportGenPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      {
        path: 'students/:id',
        name: 'schl-student-profile',
        component: () => import(/* webpackChunkName: "sch" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      {
        path: 'students/skill/:id',
        name: 'schl-student-skill-detail',
        component: () => import(/* webpackChunkName: "sch" */ '../views/StudentPortal/Skills/SkillDetailPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      // Prizes/Rewards
      {
        path: 'redeem-rewards',
        name: 'redeem-rewards',
        component: () => import(/* webpackChunkName: "sch" */ '../views/Payment/RedeemCredits.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },

      {
        path: 'rewards/redemption-history',
        name: 'sch-prize-redemption-history',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/Payment/PrizeCreditsHistory.vue'),
      },

      //Free Plan Pages
      {
        path: 'upgrade-plan',
        name: 'upgrade-plan',
        component: () => import(/* webpackChunkName: "auth" */ '../views/SchoolPortal/UpgradePlan/UpgradePlanPage.vue'),
      },
      // Employers
      // {
      //   path: 'employers',
      //   name: 'schl-employers-list',
      //   component: () => import(/* webpackChunkName: "sch" */ '../views/Employers/Employers.vue'),
      //   meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] }
      // },
      {
        path: 'employers/:id',
        name: 'schl-employer-profile',
        component: () => import(/* webpackChunkName: "sch" */ '../views/Employers/EmployerProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
      //Invites
      {
        path: 'manage-invites/send-invite',
        name: 'manage-student-invites',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/InviteStudents/PostInvite.vue'),
      },
      {
        path: 'manage-invites',
        name: 'manage-student-invite-list',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/InviteStudents/InviteList.vue'),
      },
      //Testimonials
      {
        path: 'testimonials',
        name: 'manage-student-testimonial-list',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Testimonials/TestimonialList.vue'),
      },
      {
        path: 'testimonials/:id',
        name: 'show-student-testimonial',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Testimonials/TestimonialShowPage.vue'),
      },
      //SignUp Requests
      // {
      //   path: 'sign-up-requests',
      //   name: 'manage-student-signup-list',
      //   meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
      //   component: () =>
      //     import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/StudentSignup/StudentSignupList.vue'),
      // },
      // {
      //   path: 'sign-up-requests/:id',
      //   name: 'show-student-sign-up-request',
      //   meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] },
      //   component: () =>
      //     import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/StudentSignup/SignUpRequestShowPage.vue'),
      // },

      // Request Partnership (use if needed)
      // {
      //   path: 'employers/request-partnership/:id',
      //   name: 'school-request-partnership',
      //   component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/RequestPartnershipPage.vue'),
      //   meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER] }
      // }

      //Resources Management
      {
        path: 'resources',
        component: Layout,
        children: [
          {
            path: '',
            name: 'school-manage-resources',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/ListResources.vue'),
          },
          {
            path: 'add',
            name: 'sch-add-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/AddResource.vue'),
          },
          {
            path: 'edit/:id',
            name: 'sch-edit-resource',
            meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
            component: () =>
              import(/* webpackChunkName: "adm" */ '../views/SuperAdminPortal/Resources/AddResource.vue'),
          },
        ],
      },
      {
        path: 'feature-updates',
        name: 'feature-updates-page',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/FeatureUpdates/FeatureUpdates.vue'),
      },
      {
        path: 'feature-updates/:id',
        name: 'feature-update-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        component: () =>
          import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/FeatureUpdates/FeatureUpdateDetails.vue'),
      },

      {
        path: 'mark-attendance',
        name: 'mark-attendance',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Attendance/MarkAttendance.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        beforeEnter: basicPayment,
      },

      {
        path: 'edit-attendance',
        name: 'edit-attendance',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Attendance/MarkAttendance.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        beforeEnter: basicPayment,
      },

      {
        path: 'view-attendance',
        name: 'view-attendance',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Attendance/ViewAttendance.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
        beforeEnter: basicPayment,
      },

      {
        path: 'manage-tags',
        name: 'manage-tags',
        component: () => import(/* webpackChunkName: "sch" */ '../views/SchoolPortal/Tags/ManageTags.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN] },
      },
    ],
  },

  // Employer Portal Routes
  {
    path: '/employer',
    meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
    component: Layout,
    name: 'employer-home',
    redirect: 'employer/dashboard',
    children: [
      // Company Profile Management
      {
        path: 'company-profile',
        name: 'manage-company-profile',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Profile/ManageEmployerProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
      },
      {
        path: 'setup-guide',
        name: 'employer-setup-guide',
        component: () =>
          import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/SetupGuide/EmployerSetupGuide.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
      },
      {
        path: 'dashboard',
        name: 'dashboard-employer',
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/Employers/Dashboard/EmployerDashboard.vue'),
      },

      {
        path: 'company-profile/public-view',
        name: 'emp-public-company-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Employers/EmployerProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN] },
      },

      // Candidates / Students
      {
        path: 'candidates',
        name: 'employer-candidates-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/Students.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'unlocked-candidates',
        name: 'employer-unlocked-candidates-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/Students.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'unlocked-candidates/:id',
        name: 'employer-unlocked-candidate-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'candidates/:id',
        name: 'employer-candidate-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'candidates/skill/:id',
        name: 'emp-candidate-skill-detail',
        component: () => import(/* webpackChunkName: "sch" */ '../views/StudentPortal/Skills/SkillDetailPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },

      // Schools
      {
        path: 'schools',
        name: 'emp-schools-list',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/Schools.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'schools/:id',
        name: 'emp-school-profile',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Schools/SchoolProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      //Partnership
      {
        path: 'schools/request-partnership/:id',
        name: 'employer-request-partnership',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/RequestPartnershipPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'schools/partnership-terms/:id',
        name: 'emp-school-partnership-terms',
        component: () => import(/* webpackChunkName: "account" */ '../views/Partnership/PartnershipTermsPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      //Hire
      {
        path: 'hires',
        name: 'hires',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Hire/HiresList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },
      {
        path: 'hires/make-a-hire',
        name: 'make-a-hire',
        component: () => import(/* webpackChunkName: "emp" */ '../views/EmployerPortal/Hire/PostHire.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
      },

      {
        path: 'custom-questions',
        component: Layout,
        children: [
          {
            path: '',
            name: 'emp-manage-questions',
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
            component: () =>
              import(/* webpackChunkName: "emp" */ '../views/OrganizationPortal/Questions/QuestionsList.vue'),
          },
          {
            path: 'add',
            name: 'emp-add-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/OrganizationPortal/Questions/PostQuestion.vue'),
          },
          {
            path: 'edit/:id',
            name: 'emp-edit-question',
            meta: { auth: true, rolesRequired: [USER_ROLES.EMPLOYER_ADMIN, USER_ROLES.EMPLOYER_CAREER_SPECIALIST] },
            component: () =>
              import(/* webpackChunkName: "sch" */ '../views/OrganizationPortal/Questions/PostQuestion.vue'),
          },
        ],
      },
    ],
  },

  // Partner Portal Routes
  {
    path: '/partner',
    meta: { auth: true, rolesRequired: [USER_ROLES.PARTNER] },
    component: Layout,
    name: 'partner-home',
    redirect: 'partner/jobs',
    children: [
      {
        path: 'jobs',
        name: 'partner-jobs-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.PARTNER] },
        component: () => import(/* webpackChunkName: "std" */ '../views/Partners/Jobs/JobsListing.vue'),
      },
      {
        path: 'jobs/:id',
        name: 'partner-job-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.PARTNER] },
        component: () => import(/* webpackChunkName: "std" */ '../views/Jobs/JobShowPage.vue'),
      },
    ],
  },

  // Student Portal Routes
  {
    path: '/student',
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    component: Layout,
    name: 'student-home',
    redirect: 'student/home',
    children: [
      // Dashboard
      {
        path: 'home',
        name: 'student-dashboard',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Dashboard/Dashboard.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'university-checklist/:slug',
        name: 'std-cc-uni-checklist',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Dashboard/CareCampusUniChecklist.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'myinfo',
        name: 'student-info',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/MyInfo/MyInfo.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'advisors',
        name: 'student-advisors',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Advisor/AdvisorList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'advisors/:id',
        name: 'student-advisor-profile',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Advisor/AdvisorProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'sponsorship-spotlight',
        name: 'student-sponsorship-spotlight',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SponsorshipSpotlight.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        beforeEnter: isStoryExistsGaurd,
      },

      {
        path: 'sponsorship-spotlight/:submission',
        name: 'verify-student-sponsorship-spotlight',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/SpotlightSuccessComponent.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      {
        path: 'manage-sponsorship-spotlight',
        name: 'update-sponsorship-spotlight',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../views/StudentPortal/Scholarships/SponsorshipSpotlightUpdate.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        beforeEnter: isStoryExistsGaurd,
      },

      //Community
      {
        path: 'resources',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: Layout,
        children: [
          {
            path: '',
            name: 'student-community',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Community/Community.vue'),
          },
          {
            path: ':slug',
            name: 'resource-detail',
            meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
            component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Community/ResourcePage.vue'),
          },
        ],
      },
      // Onboarding
      {
        path: 'onboarding',
        name: 'student-onboarding',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Onboarding/Onboarding.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      //Testimonial
      {
        path: 'post-testimonial',
        name: 'manage-student-testimonial',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "adm" */ '../views/StudentPortal/Testimonial/PostTestimonial.vue'),
      },

      // Profile Management
      {
        path: 'my-profile',
        name: 'student-my-profile',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Profile/ManageStudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'intro-video',
        name: 'manage-intro-video',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Profile/ManageIntroVideoPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      // PIIS / Records & Credentials
      {
        path: 'manage-records',
        name: 'std-manage-records',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Profile/ManageStudentPIIs.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      // Resume Management
      {
        path: 'resume',
        name: 'std-manage-resume',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Resume/ManageResume.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      {
        path: 'applied-applications',
        name: 'std-manage-applications',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Scholarships/ManageApplications.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'applied-applications/:id',
        name: 'std-application',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Scholarships/ApplicationForm.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      // Payment List
      {
        path: 'payment-list',
        name: 'std-payment-list',
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/MyPayments/MyPayments.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'my-profile/public-view',
        name: 'std-profile-public-view',
        component: () => import(/* webpackChunkName: "std" */ '../views/Students/StudentProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'my-skills',
        name: 'my-skills',
        beforeEnter: premiumPayment,
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Skills/ManageSkillPage.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      // Employers
      {
        path: 'careers/companies',
        name: 'student-employers-list',
        component: () => import(/* webpackChunkName: "std" */ '../views/Employers/Employers.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'employers/:id',
        name: 'student-employer-profile',
        component: () => import(/* webpackChunkName: "std" */ '../views/Employers/EmployerProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },

      // Careers

      // Events (temporarily disabled)
      // {
      //   path: 'events',
      //   component: Layout,
      //   meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      //   children: [
      //     {
      //       path: '',
      //       name: 'student-events',
      //       meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      //       component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Events/EventsListing.vue')
      //     },
      //     {
      //       path: ':id',
      //       name: 'student-event-details',
      //       meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      //       component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Events/EventShowPage.vue')
      //     }
      //   ]
      // },

      {
        path: 'manage-plans',
        name: 'manage-plans',
        component: () => import(/* webpackChunkName: "account" */ '../views/StudentPortal/MyPayments/PlansList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        beforeEnter: inAppPaymentGuard,
      },
      {
        path: 'manage-plans/:id',
        name: 'payment-plan-details',
        component: () => import(/* webpackChunkName: "account" */ '../views/StudentPortal/MyPayments/PlanDetails.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        beforeEnter: inAppPaymentGuard,
      },
    ],
  },
  {
    path: '/student/career-pathways',
    component: Layout,
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    children: [
      {
        path: 'organizations',
        name: 'student-org-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Careers/OrganizationList.vue'),
      },
      {
        path: 'universities',
        name: 'student-uni-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Careers/UniList.vue'),
      },
      {
        path: '',
        name: 'student-careers',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Careers/Careers.vue'),
      },
      {
        path: ':id',
        name: 'student-career-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Careers/CareerShowPage.vue'),
      },
    ],
  },
  // Jobs
  {
    path: '/student/careers/jobs',
    component: Layout,
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    children: [
      {
        path: 'featured',
        name: 'student-local-jobs-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Jobs/JobsListing.vue'),
      },
      {
        path: '',
        name: 'student-jobs-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Jobs/JobsListing.vue'),
      },
      {
        path: 'external',
        name: 'student-external-jobs-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Jobs/ExternalJobs/ExternalJobs.vue'),
      },
      {
        path: ':id',
        name: 'student-job-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/Jobs/JobShowPage.vue'),
      },
      {
        path: 'featured/:id',
        name: 'student-local-job-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/Jobs/JobShowPage.vue'),
      },
    ],
  },
  {
    path: '/student/sponsorships',
    component: Layout,
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    children: [
      {
        path: '',
        name: 'student-scholarships-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Scholarships/ScholarshipsListing.vue'),
      },
      {
        path: ':id',
        name: 'student-scholarship-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () => import(/* webpackChunkName: "std" */ '../views/Scholarships/ScholarshipDetailPage.vue'),
      },
      {
        path: '/application/:id',
        name: 'sponsorship-form',
        component: () => import(/* webpackChunkName: "std" */ '../views/Applications/ApplicationForm.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
    ],
  },
  {
    path: '/student/simulations',
    component: Layout,
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    children: [
      {
        path: '',
        name: 'student-simulations-listing',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Simulations/SimulationsListing.vue'),
      },
      {
        path: 'submissions',
        name: 'student-submissions-listing',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Simulations/SimulationSubmissionList.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: 'submissions/:id',
        name: 'student-submission-profile',
        component: () => import(/* webpackChunkName: "std" */ '../views/Simulations/SimulationSubmissionProfile.vue'),
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
      },
      {
        path: ':id',
        name: 'student-simulation-details',
        meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER] },
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/StudentPortal/Simulations/SimulationDetail.vue'),
      },
    ],
  },
  {
    path: '/student/attendance',
    name: 'student-attendance',
    meta: { auth: true, rolesRequired: [USER_ROLES.STUDENT] },
    beforeEnter: basicPayment,
    component: () => import(/* webpackChunkName: "std" */ '../views/StudentPortal/Attendance/MyAttendance.vue'),
  },

  {
    path: '/auth',
    name: 'student-auth',
    meta: { auth: false },
    component: () => import(/* webpackChunkName: "std" */ '../views/Auth/DashboardVerification.vue'),
  },
  // beforeEnter: basicPayment,

  // School Public Landing
  {
    path: '/school-landing',
    meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
    component: Layout,
    children: [
      // Home
      {
        path: '',
        name: 'sch-public-home',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Home/SchlPublicHome.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },

      // Public Jobs
      {
        path: 'jobs',
        name: 'sch-public-jobs',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Jobs/PublicJobs.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },

      // Public Careers
      {
        path: 'careers',
        name: 'sch-public-careers',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Careers/PublicCareers.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },

      // Public Resume
      {
        path: 'resume',
        name: 'sch-public-resume',
        component: () => import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Resume/PublicResume.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },

      // Public Resume
      {
        path: 'partnerships',
        name: 'sch-public-parnerships',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Partnerships/PublicPartnerships.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      //Public Employer Profile
      {
        path: 'companies/:slug',
        name: 'emp-public-profile',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Employers/PublicEmployerProfile.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      // Public Interviews
      {
        path: 'interviews',
        name: 'sch-public-interviews',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Interviews/PublicInterviews.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      //Public Testimonials
      {
        path: 'testimonials',
        name: 'sch-public-testimonials',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Testimonials/PublicTestimonialList.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
      //Public Employers

      {
        path: 'employers',
        name: 'sch-public-employers',
        component: () =>
          import(/* webpackChunkName: "std" */ '../views/SchoolPublicLanding/Employers/PublicEmployers.vue'),
        meta: { auth: false, onlyUnauth: true, onlyUnauthSchool: true },
      },
    ],
  },
  //feedback
  {
    path: '/submit-feedback',
    name: 'submission-feedback',
    component: () =>
      import(/* webpackChunkName: "adm" */ '../views/SchoolPublicLanding/Simulations/PublicSimulationFeedback.vue'),
  },
  {
    path: '/submission-feedback-successful/:submission',
    name: 'submission-feedback-success',
    component: () =>
      import(/* webpackChunkName: "adm" */ '../views/SchoolPublicLanding/Simulations/SuccessSimulationComponent.vue'),
  },
];
