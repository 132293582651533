import axios from 'axios';
const state = {};
const getters = {};

const mutations = {};
const actions = {
  async getAllOrganizations(_obj, params) {
    const res = await axios.get(`api/organizations/`, { params });
    return res.data;
  },

  async getOrganization(_obj, id) {
    const res = await axios.get(`api/organizations/${id}/`);
    return res.data;
  },

  async createOrganization(_obj, data) {
    return axios.post(`api/organizations/`, data);
  },

  async updateOrganization(_obj, { id, data }) {
    return axios.patch(`api/organizations/${id}/`, data);
  },
  async getOrgImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/organizations/upload-url/`, data)).data.data;
  },
  async updateMyOrgProfile(_obj, data) {
    const resp = await axios.patch(`api/organizations/update-org-profile/`, data);
    return resp;
  },
  async createHomepageConfig(_obj, data) {
    return axios.patch(`api/organizations/update-org-site-homepage/`, data);
  },
  async getHomePageConfig(_obj, params) {
    const res = await axios.get(`api/organizations/org-site-homepage/`, { params });
    return res.data.data;
  },

  async createPagesConfig(_obj, data) {
    return axios.patch(`api/organizations/update-org-page-config/`, data);
  },
  async getPagesConfig(_obj, params) {
    const res = await axios.get(`api/organizations/org-page-config/`, { params });
    return res.data.data;
  },

  async createBasicConfig(_obj, data) {
    return axios.patch(`api/organizations/update-org-website/`, data);
  },
  async getBasicConfig(_obj, params) {
    const res = await axios.get(`api/organizations/org-website/`, { params });
    return res.data.data;
  },

  async updateNavbarMenuLabel(_obj, data) {
    const resp = await axios.patch(`api/organizations/update-org-site-navbar/`, data);
    return resp;
  },

  async getOrgFromReffId(_obj, params) {
    const res = await axios.get(`api/organizations/public-unique-org/`, { params });
    return res.data;
  },
  async getEmpOrgCredits(_obj, params) {
    const res = await axios.get(`api/organizations/emp-org-credits/`, { params });
    return res.data;
  },
  async getEmpUnlockCandidateEnable(_obj, params) {
    const res = await axios.get(`api/organizations/emp-unlock-candidate-enable/`, { params });
    return res.data;
  },
  async getDashboard(_obj, params) {
    const res = await axios.get(`api/organizations/organization-dashboard/`, { params });
    return res.data;
  },
  async getOrgSetupGuideStatuses(_obj, params) {
    const res = await axios.get(`api/organizations/setup-guide-statuses/`, { params });
    return res.data;
  },

  async getOrgFromSlug(_obj, params) {
    const res = await axios.get(`api/organizations/public-org-from-slug/`, { params });
    return res.data;
  },
  async getSchPartneredOrgs(_obj, data) {
    const res = await axios.post(`api/organizations/school-partnered-orgs/`, data);
    return res.data;
  },
  async getSchPartneredOrgsList(_obj, params) {
    const res = await axios.get(`api/organizations/school-orgs/`, { params });
    return res.data;
  },
  async getOrganizationFromInvite(_obj, params) {
    const res = await axios.get(`api/organizations/from-invite/`, { params });
    return res.data;
  },
  async getOrganizationResources(_obj, params) {
    const res = await axios.get(`api/organizations/resource/`, { params });
    return res.data;
  },
  async getOrganizationResource(_obj, id) {
    const res = await axios.get(`api/organizations/resource/${id}/`);
    return res.data;
  },
  async updateOrgResource(_obj, { id, data }) {
    return axios.patch(`api/organizations/resource/${id}/`, data);
  },
  async deleteOrgResource(_obj, id) {
    return axios.delete(`api/organizations/resource/${id}/`);
  },
  async createOrgResource(_obj, data) {
    return axios.post(`api/organizations/resource/`, data);
  },
  async getOrgResourceFromSlug(_obj, params) {
    const res = await axios.get(`api/organizations/resource/org-resource-from-slug/`, { params });
    return res.data;
  },
};

export const organization = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
